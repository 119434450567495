.topContainer_SelectCustomerSideBar {
  width: 100%;
  margin-top: 8%;
}
.heading_SelectCustomerSideBar {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 8%;
}
.searchbarGrid_SelectCustomerSideBar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  border-radius: 9px;
  height: 30px;
  padding-left: 10px;
  margin-top: 8%;
  margin-bottom: 8%;
}
.textfield_SelectCustomerSideBar {
  width: 100%;
  height: 75%;
  border-radius: 9px;
  border: none;
  outline: none;
}
.inputPropStyle_SelectCustomerSideBar {
  font-size: 20px;
  margin-left: 10px;
}
.stylinghrtags_SelectCustomerSideBar {
  display: flex;
  flex-direction: row;
  /* width: 40%; */
  justify-content: center;
  width: 100%;
}
.txtinputs_styling {
  background-color: #f8f8f8;
  border-radius: 8px;
  width: 100%;
  padding: 6px;
}
.allinputpositioningstyling {
  display: flex;
  align-items: center;
  margin-bottom: 10%;
}
.selected_payment_mode {
  font-size: 14;
  font-weight: 600;
  margin-top: 2%;
}
.creditNoteButton {
  border-radius: 8px;
  color: #ffffff;
  background-color: #00c7b1;
  min-width: 50px;
  margin-right: 10px;
  padding: 2%;

  margin-bottom: 8%;
}
.ProceedToPayButton {
 
  border-radius: 8px;
  color: #ffffff;
  background-color: #002d8c;
  min-width: 100%;
  margin-right: 10px;
  padding: 2%;
  margin-top: 4%;
}

.addCustomersGridStyling {
  margin-bottom: 4%;
}
.AddCustomers_SelectCustomerSideBar {
  margin-bottom: 2%;
  font-size: 16px;
  font-weight: 800;
}
.commonInput {
  background-color: #f8f8f8;
  width: 100%;
  /* height: 24px; */
  margin: 1px;
  padding: 5px;
  border-radius: 8px;
}
.inputLabel {
  display: flex;
  flex-direction: row;
  font-family: Medium;
  font-size: 12px;
  height: 24px;
  align-items: center;
}


.asteriskContainer {
  color: red;
  width: 10px; 
  display: inline-block;
  text-align: center;
}
.requiredIndicator.visible {
  margin-top: 12px;
  font-size: 22px;
  visibility: visible; 
}
@media screen and (max-width: 600px) {
  .allinputpositioningstyling {
    display: flex;
    align-items: center;
    margin-left: 3%;
  }
}

@media screen and (max-width: 1024px) {
  .allinputpositioningstyling {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .allinputpositioningstyling {
    display: flex;
    align-items: center;
    margin-left: 3%;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .allinputpositioningstyling {
    display: flex;
    align-items: center;
    margin-left: 3%;
  }
}
