.top_container {
  display: block;
  align-items: center;
  height: 100vh;
}
.head_container_cont {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.icon_btn {
  background-color: #4cb748;
  width: 3rem;
  height: 2rem;
  border-radius: 8px;
  text-align: center;
}
.txtstyles {
  font-size: 16px;
  font-family: GilroyBold;
  margin: 0.9rem;
}
.secondary_head_cont {
  display: flex;
  flex-wrap: wrap;
}
.mainnn_cont_business {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.businessName_inputlabel {
  width: 30%;
}
.businessaddress_inputlabel {
  width: 30%;
}
.mainn_container_person {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.contact_person_inputlabel {
  width: 30%;
}
.empty_inputlabel {
  width: 30%;
}
.email_address_input {
  width: 30%;
}
.mainn_container_phone {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.phone_number_input {
  width: 30%;
}
.gst_number_input {
  width: 30%;
}
.txtinputs_styling_empty {
  background-color: #e0e0e0;
  border-radius: 8px;
  margin: 2px;
  width: 90%;
  padding: 5px;
  margin-top: 20px;
}
.Profile_SOA {
  margin-top: 2%;
}
.profilepicture_profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;  
}
.image_viewprofile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50%;
  border-radius: 15px;
}
.headerText_Styling {
  margin-bottom: 2%;
}

@media screen and (max-width: 600px) {
  
  .mainnn_cont_business {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .businessName_inputlabel {
    width: 100%;
  }

  .businessaddress_inputlabel {
    width: 100%;
  }

  .mainn_container_person {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .contact_person_inputlabel {
    width: 100%;
  }
  .empty_inputlabel {
    width: 100%;
  }
  .email_address_input {
    width: 100%;
  }
  .mainn_container_phone {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .phone_number_input {
    width: 100%;
  }
  .gst_number_input {
    width: 100%;
  }
  .image_viewprofile {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 70%;
    height: 70%;
    border-radius: 15px;
    /* margin-right: 8%; */
  }
  .profilepicture_profile {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 601px) and (max-width: 767px) {
  .profilepicture_profile {
    width: 100%; 
    height: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image_viewprofile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    border-radius: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .profilepicture_profile {
    width: 100%; 
    height: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
    margin-left: 3%;
  }
  .image_viewprofile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .profilepicture_profile {
    width: 100%; 
    height: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
  }
  .image_viewprofile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    border-radius: 15px;
    margin-right: 8%;
  }
}

@media (min-width: 1440px) {
  .profilepicture_profile {
    width: 100%; 
    height: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image_viewprofile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    height: 65%;
    border-radius: 15px;
    margin-right: 8%;
  }
}
