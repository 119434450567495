.text-containerrrr-navbarrrrSideBar {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.optionsss-styling-navbarrrSideBar {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Medium;
  cursor: pointer;
  margin-right: 5%;
  margin-top: 2%;
  padding: 1%;
}
.optionsss-styling-navbarrrSideBar.selectedddd-route-text_sideBar {
  color: #20479a;
  background-color: #f2f9ff;
  padding: 1%;
  font-weight: 800;
  font-size: 16px;
}
.iconStyle-NavSideBar-NavBarrr {
  font-size: 12px;
  padding: 1%;
}
.iconStyle-NavSideBar-NavBarrr.selectedddd-route-text_sideBar {
  color: #20479a;
  background-color: #f2f9ff;
  padding: 1%;
  font-weight: 800;
  font-size: 14px;
}

/* @media screen and (max-width: 600px) {
    .img-containerrr-navbarrr {
      display: block;
      justify-content: center;
      
    }
    .text-containerrrr-navbarrrr {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
    .optionsss-styling-navbarrr {
      font-size: 14px;
      font-family: Medium;
      cursor: pointer;
    }
    .buttonStyling_navbarrr {
      border-radius: 5px;
      color: #133c94;
      background-color: #ffffff;
      min-width: 30px;
      margin-right: 10px;
      padding: 1%;
      font-family: Medium;
      font-size: 8px;
    }
    .buttonStyling_navbarrr_agentportal {
      border-radius: 8px;
      color: #ffffff;
      background-color: #002d8c;
      min-width: 30px;
      margin-right: 10px;
      padding: 1%;
      font-family: Medium;
      font-size: 8px;
    }
    .optionsss-styling-navbarrr {
      font-size: 8px;
      font-family: Medium;
      cursor: pointer;
      margin-right: 5%;
    }
  }
  
  @media (min-width: 600px) and (max-width: 767px) {
    .img-containerrr-navbarrr {
      display: flex;
      justify-content: start;
      
    }
    .text-containerrrr-navbarrrr {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
    .optionsss-styling-navbarrr {
      font-size: 14px;
      font-family: Medium;
      cursor: pointer;
    }
    .buttonStyling_navbarrr {
      border-radius: 5px;
      color: #133c94;
      background-color: #ffffff;
      min-width: 30px;
      margin-right: 10px;
      padding: 1%;
      font-family: Medium;
      font-size: 12px;
    }
    .buttonStyling_navbarrr_agentportal {
      border-radius: 8px;
      color: #ffffff;
      background-color: #002d8c;
      min-width: 30px;
      margin-right: 10px;
      padding: 1%;
      font-family: Medium;
      font-size: 12px;
    }
    .optionsss-styling-navbarrr {
      font-size: 12px;
      font-family: Medium;
      cursor: pointer;
      margin-right: 5%;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .img-containerrr-navbarrr {
      display: flex;
      justify-content: center;
      
    }
    .text-containerrrr-navbarrrr {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
    .optionsss-styling-navbarrr {
      font-size: 14px;
      font-family: Medium;
      cursor: pointer;
    }
    .buttonStyling_navbarrr {
      border-radius: 5px;
      color: #133c94;
      background-color: #ffffff;
      min-width: 30px;
      margin-right: 10px;
      padding: 1%;
      font-family: Medium;
      font-size: 12px;
    }
    .buttonStyling_navbarrr_agentportal {
      border-radius: 8px;
      color: #ffffff;
      background-color: #002d8c;
      min-width: 30px;
      margin-right: 10px;
      padding: 1%;
      font-family: Medium;
      font-size: 12px;
    }
    .optionsss-styling-navbarrr {
      font-size: 12px;
      font-family: Medium;
      cursor: pointer;
      margin-right: 5%;
    }
  }
   */
