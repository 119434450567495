.containerrr {
  width: 98%;
  margin: 0px auto;
  margin-right: 2px;
  border-radius: 10px;
  border: none;
  margin-bottom: 4px;
  box-shadow: 5px 5px 10px 5px #f7f7f8;
  /* display: contents; */
  overflow: visible;
  
  /* position: relative; */
}

.form-headerrr {
  background-color: #ffffff;
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: 10px 10px 30px 10px #f7f7f8; */
  /* border: none;
  outline: none; */
}

.form-headerr h2 {
  margin: 0;
}

.form-header.closedd {
  border: none;
}

.form-bodyy {
  padding: 15px;
  background-color: #fff;
  box-shadow: 5px 5px 10px 5px #f7f7f8;
  border: none;
  outline: none;
}

.inputs_styless_ {
  font-family: Medium;
  color: #636468;
  font-size: 13px;
  /* width: 100%; */
  margin-left: 8px;
  /* margin-top: 5px; */
}
.txtinputs_stylingg {
  background-color: #F8FAFB;
  border-radius: 8px;
  width: 96%;
  padding: 5px;
  margin-left: 8px;
  margin-top: 10px;
}
.css-1gnspwu-MuiGrid-root {
  background-color: #f0f0f0; /* Change background color to light gray */
  height: 0%;
  /* Add any other styles you want to apply */
}

