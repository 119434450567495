.background-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_styling {
  margin-top: 2.5rem;
  width: 500px;
  height: auto;
  border-radius: 15px;
}
@media screen and (max-width: 499px) {
  .image_styling {
    width: 300px;
    height: auto;
    border-radius: 15px;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  .image_styling {
    width: 350px;
    height: auto;
    border-radius: 15px;
  }
}

@media (min-width: 769px) and (max-width: 800px) {
  .image_styling {
    width: 350px;
    height: auto;
    border-radius: 15px;
  }
}
@media (min-width: 801px) and (max-width: 1023px) {
  .image_styling {
    width: 400px;
    height: auto;
    border-radius: 15px;
  }
}
@media (min-width: 1024) and (max-width: 1439px) {
  .image_styling {
    width: 400px;
    height: auto;
    border-radius: 15px;
  }
}
@media (min-width: 1440px) and (max-width: 1740px) {
  .image_styling {
    width: 400px;
    height: auto;
    border-radius: 15px;
  }
}
@media (min-width: 1441px) and (max-width: 1979px) {
  .image_styling {
    width: 500px;
    height: auto;
    border-radius: 15px;
  }
}
@media (min-width: 1980px) and (max-width: 2560px) {
  .image_styling {
    width: 900px;
    height: auto;
    border-radius: 15px;
  }
}
