.mainContainer_Purchase {
    display: flex;
    justify-content: space-between;
    align-items: center;
    }
    .tablebtn_purchase {
      background-color: #4cb748;
      color: #fff;
      border-radius: 20px;
      min-width: 55px;
      font-size: 12px;
      cursor: pointer;
    }
    .plusnewbtn_purchase {
      background-color: #0174bf;
      border-radius: 7px;
    }
    .VisibilityIcon_purchase {
      font-size: 10px;
    }
    
    