.main_mainContainer_products {
  background-color: #014fef;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  height: auto;
}
.our_product_heading_container_products {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  margin-top: 4%;
  margin-left: 15%;
}
.our_heading_products {
  font-size: 16px;
  font-family: Medium;
  color: #ffffff;
  margin-right: 1%;
}
.products_heading_products {
  font-size: 16px;
  font-family: Medium;
  color: #ffffff;
}
.imageContainer_products {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 20px;
}
.image____products {
  width: 60%;
  height: 60%;
  object-fit: cover;
  z-index: 2;
}
.Products_Heading_Styling {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-top: 4%;
  z-index: 2;
  margin-left: 15%;
  cursor: pointer;
}
.productname_styling_products {
  font-size: 20px;
  color: #ffffff;
}
.icon_styling_products {
  font-size: 34px;
  color: #ffffff;
}
.productname_icon_styling {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin-right: 15%;
}
.description_textStyling {
  color: #5fffdb;
  font-size: 16px;
  font-family: Medium;
}
.icon_styling_products_after {
  font-size: 34px;
  color: #5fffdb;
}
.productname_styling_products_after {
  font-size: 24px;
  color: #5fffdb;
  font-family: Medium;
  font-size: bold;
}

@media (max-width: 600px) {
  /* .productname_styling_products_after {
    font-size: 24px;
    color: #5fffdb;
    font-family: Medium;
    font-size: bold;
    width: 80%;
  }
  .productname_icon_styling {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
  }
  .Products_Heading_Styling {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-top: 4%;
    z-index: 2;
    cursor: pointer;
    width: 100%;
  } */
}

@media (min-width: 601px) and (max-width: 960px) {
}

@media (min-width: 961px) and (max-width: 1023px) {
}

@media (min-width: 1024px) and (max-width: 1440px) {
}
